import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
// import { PATH_DASHBOARD } from '../routes/paths';
import { PATH_SUPER_DASHBOARD, PATH_ADMIN_DASHBOARD, PATH_EMPLOYEE_DASHBOARD } from '../routes/paths';

import { ROLE } from '../config';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, user } = useAuth();
  const searchParams = new URLSearchParams(window.location.search);
  const returnSection = searchParams.get('return_section');

  if (window.location.pathname === '/auth/login') {
    console.log("ACA:", localStorage.getItem("register_token"))
    if (localStorage.getItem("register_token")) {
      return <Navigate to={'/auth/register'} />
    }
  }

  if (isAuthenticated) {
    // role based redirect
    if(user && user.role_id.toString() === ROLE.SUPER){
      return <Navigate to={PATH_SUPER_DASHBOARD} />;
    }else if(user && user.role_id.toString() === ROLE.ADMIN){

      if (returnSection === 'pending_docs') {
        return <Navigate to={'/admin/docs-pending'} />;
      }

      return <Navigate to={PATH_ADMIN_DASHBOARD} />;
    }else if(user && user.role_id.toString() === ROLE.EMPLOYEE){

      if (returnSection === 'pending_docs') {
        return <Navigate to={'/employee/my-files'} />;
      }

      if(user.onboard_assigned_at !== null && user.onboarded_at === null){
        return <Navigate to={'/employee/my-info'} />;
      }else{
        return <Navigate to={PATH_EMPLOYEE_DASHBOARD} />;
      }
    }else if(user && user.role_id.toString() === ROLE.MANAGER){
      return <Navigate to={PATH_EMPLOYEE_DASHBOARD} />;
    }else{
      console.log('error, unknown role');
    }
    
    // default to 404 page
    return <Navigate to={'/role/unknown'} />;
  }

  return <>{children}</>;
}
